/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 /* menus in the middle */
	.zen-menu-center 
		{
			text-align:center;
			float:none !important;
			width:100%;
		
			li { 
				display:inline-table;
				float:none !important;
				
				
				li {
					display:block;
					float:left!important;
					text-align: left;
					width: 100%;
					text-indent: 0 !important;
	
					a {
						width:100%;
						text-align: left;
					}
					
				}

			}
	}