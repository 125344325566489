/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 .zen-menu {
	
	
	*, *:before, *:after{
	  -webkit-box-sizing: border-box;
	  -moz-box-sizing: border-box;
	  box-sizing: border-box;
	  padding: 0;
	  margin: 0;
	}
	
	
	// First level
	margin: 0;
	
	
	li {
		list-style-type: none;
		margin:0;
		
		
		
		
		// Link Items
		// Nav header
		a,
		.zen-menu-heading {
			
			cursor:pointer;
			
			&:hover {
				text-decoration:none
			}
		
		}
		
		// Active States
		&.active {
		
			 > a,
			 .zen-menu-heading {
				
				
				
				&:hover {
					
				}
			}
		}
		
		
		// Parent items
		&.parent {
			
			> a,
			> .zen-menu-heading {
				&:before {
					width: 0;
					height: 0;
					float:left;
					content:"";
					margin-top:16px;
					margin-right:8px;
					border-style: solid;
					border-width: 4px 4px 0 4px;
					border-color: #fff transparent transparent transparent;
				}
			}
			
			&.active {
				> a,
				> .zen-menu-heading {
					&:before {
						border-color: @navbar-default-link-active-color transparent transparent transparent;
					}	
				}
			}
		}	
		
		
		ul li {
		
			&.parent{
		
				> a,
				> .zen-menu-heading {
					&:before {
						float:right;
						content:"";
						width: 0;
						height: 0;
						margin:0;
						border-style: solid;
						border-width: 4px 0 4px 4px;
						border-color: transparent transparent transparent #999;
					}	
				}
			}
		}
	}
	
	
	// Second level
	ul {
		margin:0 0 0 16px;
		
		li {
			list-style-type:disc
		}
	}
	
	
		
	// Caption
	.zen-menu-caption {
		display: block;
		font-size: 90%;
		padding: 4px 0;
	}	
}

