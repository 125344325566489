/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */


// Responsive Tools
.responsive-enabled {
	
	width:100% !important;
	
	@media screen and (min-width: @tablet-max-width) {
		.hidden-desktop {
			.visuallyhidden();
		}
	
		.visible-desktop {
			display:block !important;
			visibility:visible
		}
	
		.visible-tablets,
		.visible-tablet {
			.visuallyhidden();
		}
	
		.visible-phones,
		.visible-phone {
			.visuallyhidden();
		}
	
	}




	@media screen and (min-width: @phone-max-width) and (max-width: @tablet-max-width) {
		
		.stack-tablets {
			.zg-col {
				width:100% !important;
				margin-left:0;
			}
		}
		
		.hidden-tablets,
		.hidden-tablet {
			.visuallyhidden();
		}
	
		.visible-tablets,
		.visible-tablet {
			display:block !important;
			visibility:visible
		}
		
		.visible-phones,
		.visible-phone {
			.visuallyhidden();
		}
	
	}



@media screen and (max-width: @phone-max-width) {
	

	.stack-phones {
		.zg-col {
			width:100%
		}
	}
	
	.hidden-phones,
	.hidden-phone {
		.visuallyhidden();
	}
	
	.visible-phone,
	.visible-phones  {
		display:block !important;
		visibility:visible
	}
	
	.zen-container{
		width:60%
	}
	
}


@media screen and (min-width: @navcollapse) {

	.visible-navcollapse,
	#off-canvas-menu .simple-list {
		.visuallyhidden();
	}
}


@media screen and (max-width: @navcollapse) {
	
	.visible-navcollapse {
		display:block !important;
		visibility:visible
	}
	
	.hidden-navcollapse{
		.visuallyhidden();
	}
	
	#menu ul{
		display:none
	}
	
	&.default-menu,
	&.stacked-menu {
		#menu ul{
			display:block;
			
			ul {
				display:none
			}
		}
		
		#navwrap.fixed {
			position:relative 
		}
	}
	
	// Stacked menu
	&.stacked-menu {
		#menu {
			ul {
				li {
					width:100%
				}

			}
		}
	}
	
	#menu {
	
		position:inherit !important;
		
		.off-canvas-trigger {
			position:absolute !important;
			top:-50px;
			padding:15px 12px 15px 18px !important;		

			span:before {
				font-size:100% !important;
				line-height:1.2em  !important
			}
		
		}
	}
	
	.fixed {
		.off-canvas-trigger {
			display:none !important
		}
	}
	
	
}


@media screen and (min-width: @gridcollapse) {

	.visible-gridcollapse {
		.visuallyhidden();
	}
	
	
}


@media screen and (max-width: @gridcollapse) {

	.visible-gridcollapse {
		display:block !important;
		visibility:visible
	}
	
	.hidden-gridcollapse{
		.visuallyhidden();
	}
}


// Max Widths on the main containers
@media screen and (min-width: @tablet-max-width) {
	width: @container-width;
}


@media screen and (max-width: @tablet-max-width) {

	.zen-container{
		width:@tablet-container-width
	}
}


@media screen and (max-width: @phone-max-width) {

	.zen-container{
		width:@phone-container-width
	}
}
}