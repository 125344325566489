/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 

/*------------------------------------*\
	Pagination
\*------------------------------------*/

.pagenav,
.pagination,
.pager {

	.flatlist();
	
	float:left;
	width:100%;
	padding:20px 0;
	
	
	ul {
		margin-left:0
	}
	
	
	li {
	
		line-height:3em;
		margin-right:@gutter;
	
		a {
			.btn-empty();
			background:@pagination-bg;
			border:1px solid @pagination-border;
			color:@pagination-color;
			min-height:@input-min-height;
			line-height:@input-min-height;
	
		}
		
		&.active {
			a {
				.btn();
				
				&:hover {
					background: @btn-primary-bg;
					border: 1px solid @btn-primary-border;
					color:@btn-primary-color-hover;
				}
			}
		}
		
		&.next {
			float:right;
			margin-right:0;
		}
		
		&.disabled a{
			border-color:@pagination-border;
			
			&:hover {
				background:@pagination-bg;
				border-color:darken(@pagination-border,4%);
				color:@pagination-color;
			}
		}
	}
}

.pagination-wrap {
	
	.counter {
		.small();
		.muted
	}
	
}


// Article index
.article-index,
.mainwrap .article-index  {
	
	h3 {
		display:none
	}
	
	.nav-tabs li {
		
		a.active {
			background:none !important;
			color:@link-color !important
		}
	}
	
	&.pull-right {
		width:100%
	}
}


.pagenavcounter {
	color:@gray;
	font-size:90%
}