/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */


// -------------------------------------------------------
// Joomla content
// -------------------------------------------------------

	
// Content

.article-footer {
	margin-bottom:@gutter
}

.article-aside {
	margin-bottom:@gutter
}

.items-leading,
.item-row .item {
	
		margin-bottom:@gutter * 2
	
}


.item-row {
	margin-bottom:@gutter;
	float:left;
	width:100%
}

.items-more {
	margin-bottom:@gutter;
	float:left;
	width:100%
}

.zg-col-3,
.zg-col-4 {
	
	.article-info {
		
		margin-bottom:4px;
				
		li {
			display:block;
			float:none;
		}
	}
	
	.article-title {
		font-size:1.8em
	}
	
	.tags span {
		//display:block
	}
	
	
	.readmore {
		a {
			display:inline-block
		}
	}
	
	.item {
		margin-bottom:30px;
	}
}


// Category Lists
.category-list {

	ul {
		margin-bottom:30px;
	}
	
	
	li {
		padding:8px 0
	}
	
	
	.cat-list-row0 {

	}

	.cat-list-row0 {

	}
	
	.btn {
		margin-top:20px;
	}
}

table.category {
	width:100%;
	
	th {
		text-align:left;
		font-weight:bold;
	}
}

// Item Images Content
.item-image {
	margin:@gutter 0
}


.img-left {
	float:left;
	max-width:100%;
	margin:0 @gutter @gutter 0;
}

.img-right{
	float:right;
	max-width:100%;
	margin:0 0 @gutter @gutter;
}

.img-none img{
	margin:0 auto @gutter
}

@media screen and (max-width:@tablet-max-width) {
	.img-right,.img-left {
		margin:@gutter 0;
	}
}




// Login Form
#login-form {

	fieldset {
		padding:0;
		border:0
	}
	
	input {
		margin-bottom:@gutter
	}
	
	ul {
	
		margin:@gutter 0 0 0;
		
		li {
			list-style-type:none;
			padding:2px 0;
		
		}
	}
	
	input#remember,
	label {
		display:inline
	}
}


// Search Results
.search-results {

	.last-child();

	padding-top:@gutter;
	border-top:1px solid @border-color;
	
		
	h3,p {
		margin:0;
	}
	
	.small {
		font-size:90%
	}
	
	li {
		border-bottom:1px solid @border-color;
		padding:@gutter 0;
	}
	
}


// Search Form
#searchForm {
	
	.no-margin {
		margin-left:0 !important
	}
	
	
	
	input[type="radio"],
	input[type="checkbox"] {
		margin-right:@gutter / 3
	}
	
	legend {
		margin-bottom:@gutter
	}
	
	
}



// Tooltips
/* Tooltips
-----------------------------------------------------------------------------*/
.control-group {
	position:relative;
	z-index:1
}

.tip-wrap {
  background: @primary;
  border: 1px solid darken(@primary, 4%);
  .border-radius (5px);
  .box-shadow (0 0 3px rgba(0,0,0,.4));
  max-width: 300px;
  z-index: 999;
  color:#fff;
}

.tip-title {
  border-bottom:  1px solid darken(@primary, 4%);
  font-weight: bold;
  padding: 5px 10px;
}

.tip-text {
  font-size: @font-size-base;
  margin: 0;
  padding: 5px 10px;
}

.hasTip img {
  border: none;
  margin: 0 5px 0 0;
}

.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  font-size: 11px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: visible;
}

.tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: @primary;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
          position:relative;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-top-color: darken(@primary, 4%);
  border-width: 5px 5px 0;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-right-color: darken(@primary, 4%);
  border-width: 5px 5px 5px 0;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-left-color: darken(@primary, 4%);
  border-width: 5px 0 5px 5px;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: darken(@primary, 4%);
  border-width: 0 5px 5px;
}


// Profile Edit Form

.profile-edit {
	
	fieldset {
		margin-bottom:@gutter
	}
}



// Content Rating
.content_rating {

	clear:both;

	margin-bottom:@gutter;
	
	img {
		float:left
	}
	
	p {
		margin-bottom:0
	}
	
	.content_vote label{
		display:block;
		
	}
	
}



// Toolbars
.btn-toolbar {
	margin-bottom:@gutter
}


// Page Breaks
/* Pagenav - Tabs
-----------------------------------------------------------------------------*/
dl.tabs {
	
	position:relative;
	float:left;
	width:100%;
	
	&:after {
		border-bottom:1px solid @border-color;
		content:"";
		position:absolute;
		z-index:1;
		width:100%;
		height:2px;
		top:45px;
	}
	.clearfix;

	
	h3 {
		float: left;
		margin-right:2px;
		font-size: 1.3em;
		font-weight: normal;
		
		
		a {
			padding:10px 15px;	
			border-radius:3px 3px 0 0;
			margin-bottom:10px !important;
			background:@body-bg;
			
		}
	}
	
	.open h3 a {
		border:1px solid @border-color;
		border-bottom:0;
		position:relative;
		z-index:2
	}	
}



// Contact Form

.contact{
	
	// Tabs
	.nav-tabs {
		float:left;
		width:100%;
		margin-top:@gutter
	}
	
	
	.tab-content {
		padding-top:@gutter;
		float:left;
		width:100%;
		position:relative;
	}
	
	.tab-pane {
		top:0
	}
	
	.thumbnail{
		margin-bottom:@gutter * 2
	}
	
	[itemprop="jobTitle"] {
		.h1()
	}
	
	
	dd {
		padding:4px 0
	}
	
	legend {
		margin-bottom:@gutter * 2
	}
	
	
	input,textarea {
		width:75%;
		margin-bottom:@gutter
	}
	
	#jform_contact_email_copy {
		width:auto
	}
	
	label {
		display:inline-block;
		text-align:left !important
	}
	
	
	// Sliders
	.panel-heading {
		padding:10px;
		border:1px solid @border-color;
		margin-bottom: @gutter
	}
	
	.zen-collapse,
	.tab-pane {
		overflow:hidden;
		max-height:0;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		opacity:0;
	}
	
	.zen-collapse.in,
	.tab-pane.active {
		max-height:1000px;
		opacity:1;
	}
	
	
	// Plain
	.contact-address {
		padding-bottom:@gutter
	}
	
}





// Login Form
.login {
	input {
		margin-bottom:@gutter
	}
	
	.col-sm-3 {
		width:auto
	}	
}

.other-links {
	.simplelist();
	margin-top:@gutter;	
}

// Profile Edit form
.profile-edit {
	.form-group {
		width:100%;
		clear:both;
		
		input {
			margin-bottom:@gutter
		}
	}
	
	legend {
		margin-bottom:@gutter
	}
}



// Tabs
.tab-pane {
		display:none
}

.tab-pane.active {
	display:block 
}


// Slider
.zen-collapse-trigger {
	border:1px solid @border-color;
	padding:8px 20px;
	margin-bottom:0;
	cursor:pointer;
	
	&:hover {
		 .transition(all 250ms);
		 background:@primary;
		border:1px solid darken(@primary, 10%);
		h1,h2,h3,h4 {
			color:#fff
		}
	}
	
	.toggler  {
		float:right
	}
	
	.zen-icon {
		float:right
	}
}

.slide-content {

	padding:@gutter 0;
	
	.moduletable {
		margin-bottom:0;
	}
	
	p {
		margin:0;
	}
}


// Tags
.tags {
	a {
		margin-right:10px
	}
}

.tag-category {
	li {
		border-bottom:1px solid @border-color
	}
}