/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 /* =Buttons
 -----------------------------------------------------------------------------*/

.btn,
button,
input.button,
ul#filters a {
	
	.btn();
	
	// Rule for active items in Zentools filter
	&.active {
		.btn-active();
		border-radius:0 !important;
	}
}

ul#filters a.active {.btn-active() !important;}


.btn.btn-large {
	padding:11px 40px
}

.btn.btn-small{
	padding:2px 4px
}

.btn.disabled {
	border:1px solid #ccc;
	background:lighten(@btn-link-disabled-color,15%);
	color:@btn-link-disabled-color
}




// Small Buttons
.btn-mini {
	padding:4px 8px;
	font-size:90%;
	line-height:1em
}


// Alternate buttons
// --------------------------------------------------


// Success appears as green
.btn-success {
  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}



.pagenav li a, .pagination li a, .pager li a {
	float:left;
	padding:0 20px
}

.pagenav li, .pagination li, .pager li {
	margin-right:4px
}

.pagination li a, .pager li a {
	padding: 0 20px !important
}