/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
.reset () {
	border:0;
	box-shadow: none;
	background-image:none;
	background: none;
	padding: 0;
	margin: 0
}


.resetmodal() {
	position:relative;
	width:auto !important;
	left:auto!important;
	top:auto!important;
	.box-shadow(none);
	.border-radius(0)!important;
}
   