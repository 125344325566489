/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 

.off-canvas-heading{
	h3 {
		color:#fff
	}
}

.sb-close{
	position:absolute;
	right:0px;
	top:0;
	padding:16px 10px 16px 18px;
	background:lighten(@off-canvas-bg, 10%);
	color:#fff;
	cursor:pointer;
	
	&:hover {
		background:@secondary
	}
}

.mean-bar {
	.sb-close {
		display:none
	}
}

.off-canvas-body{
	padding:@gutter;
	float:left;
	width:100%;
	background:@off-canvas-bg;
	
	h1,h2,h3,h4,h5,h6 {
		color:@off-canvas-header
	}
	
	p,label {
		color:@off-canvas-text-color;
	}
	
	a,.zen-menu-heading,.separator {
		color:@off-canvas-link-color;
		
		&:hover {
			color:@secondary
		}
	}
	
	input {
		width:100%
	}
	
	input[type="checkbox"] {
		width:auto
	}
	
	.moduletable {
		#login-form ul {
			margin-bottom:@gutter * 2
		}
	}
	
	
}

.mean-nav {
	.off-canvas-trigger {
		display:none !important
	}
}


#off-canvas-menu {
	
	.zen-menu-caption 
	{
		display:block;
		font-style:italic;
		color:lighten(@off-canvas-text-color, 8%)
	}	
	
	label,
	.expand {
		color:lighten(@off-canvas-text-color, 8%)
	}
	
	ul {
	
		li {
			padding:6px 0;
			clear:both;
		}
		
		a,
		.zen-menu-heading {
			display:block
		}
		
		
		ul {
			padding-left:16px;
			margin-bottom:@gutter;
			margin-top:@gutter;
			width:90% !important;
			
			li {
				border:0
			}
			
			ul {
				margin-bottom:0;
			}
		}
	}

	p {
		margin:0 0 @gutter /2;
	}
	
	h3 {
		margin-bottom:0;
	}
	
	.moduletable {
		width:100%;
		
		input {
			width:100%
		}
		
		input[type="radio"],
		input[type="checkbox"] {
			width:auto;
			margin-tight:10px;
		}
	}
}

// Offcanvas button in navbar
#menu {
	
	position:relative;
	
	.off-canvas-trigger {
		position:absolute;
		z-index:90;
		right:0;
		border:0;
		padding:8px 20px 8px 25px;
		background: none;
		color:@navbar-default-link-color;
		
		&:hover {
			background:lighten(@navbar-default-bg, 4%);
		}
		span:before {
			font-size:130%;
			line-height:@navbar-height /2;
		}
		
		@media screen and (max-width:@nav-collapse) {
			font-size:1.8em;
			
		}
	}
}

.responsive-enabled {
	#menu {
		.off-canvas-trigger {
			padding:8px 12px !important		
		}
	}
}