/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */

/* Hidden panel */

.zen-panel {
	display:none;
	padding:@gutter;
	background:@zen-panel-bg;
	color:@zen-panel-color;
	position:absolute;
	top:100px;
	z-index:100;
	max-width:50%;
	min-width:50%;
	left:50%;
	margin-left:-25%;
	box-shadow:0px 0px 10px rgba(0,0,0,0.2);
	
	@media screen and (max-width:@tablet-max-width) {
		top:@gutter;
		max-width:90%;
		left:5%;
		margin-left:0;
	}
	
	
	h1,h2,h3,h4,h5,h6,p,li {
		color:@zen-panel-color
	}
	
	a {
		color:@zen-panel-link-color;
	
	}
	
	.zen-panel-close {
		float:right;
	}
}


#zen-overlay {
	position:fixed;
	z-index:99;
	display:none;
	//background:@overlay-bg;
	.rgbaColorIn(@overlay-bg, @overlay-transparency);
	 background-color: @result;
	top:0;
	left:0;
	height:100%;
	width:100%
}

.open-panel span{
	background:@primary;
	color:#fff;
	
	&:before {
		font-size:80%;
		margin-left:3px !important
	}
}
