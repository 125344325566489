/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 
 // Sets the bg colour for the body and html tags
 html,body {
 	background:@body-bg;
 	
 }


// Styling applied here to the div inside the containers
 .zen-spotlight {
 	border-bottom:1px solid @border-color;
 }




// Breadcrumb Style
  #breadcrumbwrap {
  	.zen-spotlight {
  		border-bottom:0;
  	}
  }
  
  
  #breadcrumb {
  	.muted();
  	
  	:after {
  		.muted();
  	}
  	.small();
  	background:none;
  	
  	ul {
  		margin:0;
  		background:none;
  		
  		
  	}
  	
  	
  }
 



