/** 
 *------------------------------------------------------------------------------
 * @package       T3 Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github 
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/t3fw
 * @Link:         http://t3-framework.org 
 *------------------------------------------------------------------------------
 */

// Prevent Bootstrap Upgrading errors
//@import "../../../plugins/system/t3/base-bs3/less/variables.less";

// Bootstrap mixins
//@import "../../../plugins/system/t3/base-bs3/bootstrap/less/mixins.less";

// Base variables
@import "variables.less";

.mce-content-body {
	padding:20px
}
.edit {
		
	.btn-group {
		float:left;
		margin-top:@gutter;
		margin-right:4px;
		
		button {
			border:0;
		}
	}
	
	label {
		width:20%;
		float:left;
		margin-right:10px;
		text-align:right
	}
	
	.form-group {
		margin-bottom:@gutter
	}
	
	.tab-content {
		padding-top:@gutter;
		float:left;
		width:100%
	}
	
	.chzn-choices {
		padding:10px;
		border:1px solid @border-color;
		box-shadow:none	
	}
	
	.modal-button {
		margin-right:4px;
		
	}

}


//
// EDIT & SUBMIT ARTICLE FORM
// ---------------------------------------------------------
.edit {

  fieldset {
  }
  
  fieldset legend {
  }
  
  label {
  }

  .inputbox, input[type="text"],
  select.inputbox, select {

  }

  textarea {
    width: 70%;
  }

  .input-append,
  .input-prepend {
    float:left;
    width: auto;
   

    .btn {
      	border: 1px solid @btn-default-border;
      	background-color: @btn-default-bg;
		text-shadow:0
    }
  }
  
  

  	
  	.tab-pane {
  		display:none
  	}
  	
  	.tab-pane.active {
  		display:block
  	}
  	
  	#jform_title {
  		width:100%;
  		margin-bottom:@gutter
  	}
  	
  	#jform_title-lbl {
  		text-align:left
  	}
  	
  	.nav-tabs {
  		margin:@gutter 0 @gutter * 2
  	}
  	
  	.btn-toolbar {
  		margin:0 0 @gutter
  	}
  	
  	.form-group {
  		margin-bottom:@gutter
  	}
  	
  	.chzn-container {
  		width:70% !important
  	}
  	
  
 

}


// Tabs
// -----------------------
.edit {
  .nav-tabs {
    margin-bottom: @gutter;
  }
  .tab-pane {
    .clearfix();
  }
}


// Editor Buttons
// ----------------------
#editor-xtd-buttons,
.toggle-editor {
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
  .btn {
    background-color: @btn-default-bg;
    border-color: @btn-default-border;
  }
}

#editor-xtd-buttons {
  margin-right: @grid-gutter-width;
}



// 
// FRONTEDIT ELEMENTS 
// ---------------------------------------------------------

// Window Wrapper
// ---------------------------------------
#sbox-window {
  padding: 0;
  .box-sizing(content-box); // Reset Box-Sizing model

  // With shadow
  &.shadow {
  }

  // Content
  #sbox-content {
  }

  .sbox-content-iframe {
  }

}

// Close Button
#sbox-btn-close {
}

// Overlay Layer
#sbox-overlay {
}


// Frontend Edit Button
// ---------------------------------------
.btn.jmodedit {
  padding: 0;
  &:focus, &:active {
    box-shadow: none;
  }
}

.jfedit-menu + .tooltip {
  min-width: 100px;
}


// Frontend Edit Elements
// ---------------------------------------
.window {

  // Form Table
  // ----------
  form table {
    border: 1px solid @table-border-color;
    background: @table-bg-accent;
    margin-bottom: @line-height-computed;
    td {
      padding: @table-cell-padding;
    }
  }

  // Form Help Block
  // ---------------
  form .help-block {
    font-size: @font-size-small;
    clear: both;
    padding-top: 5px;
  }

}

.chzn-container {
  float: left;
  min-width:300px
}


// Media Manager
// -------------
.window {

  // Main Form
  #imageForm {
    margin: 0;
    width: auto;

    .chzn-container {
      float: left;
      min-width:300px
    }

    #upbutton {
      border: 1px solid @btn-default-border;
      float: left;
      padding: 3px 12px;
      margin-left: 5px;
    }

    .pull-right {
      margin-right: @grid-gutter-width;
    }

  }

  // Upload Form
  #uploadForm {
    width: auto;

    #upload-file {
      margin: 10px 0;
    }
  }

 
  // Images Choser Iframe
  #imageframe {
  }

  .manager {
    margin: 0;
    padding: 0;
    .clearfix();
    .thumbnail {
      float: left;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

// End
}


//
// Special Radio Styles for T3
// ------------------------------
.t3onoff {

  width: 90px;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  padding: 0;
  position: relative;
  border: 1px solid #aaa;
  border-radius: 0;

  input[type=radio] {
    display: none;
  }

  label {
    width: 90px;
    height: 30px;
    overflow: hidden;
    display: block;
    border-radius: 0;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 1;
    text-transform: uppercase;
    background: url(../imgs/blank.gif) no-repeat transparent;
    text-indent: -999em;
  }

  /* use before as background */
  label:before,
  label:after {
    display: block;
    position: absolute;
    top: 0;
    border-radius: 0;
    border: 1px solid #aaa;

    .transition(all 250ms);
  }

  label:before {
    content: "ON";
    width: 100%;
    height: 100%;

    text-indent: 0;
    color: white;
    padding: 4px 18px;
    font-weight: normal;
  }

  /* use after as switch */
  label:after {
    content: "";
    width: 40%;
    height: 100%;
    background: #fff;
  }

  label.off:before {
    content: "OFF";
    text-align: right;
    color: #555;
  }

  /* active label should be under => so inactive can be clickable */
  label.active {
    z-index: 0;
  }

  /* off background */
  label.off:before {
    background: #eee;
    left: 100%;
  }

  label.off.active:before {
    left: 0%;
  }

  label.on:before {
    background: #690;
    left: -100%;
  }

  label.on.active:before {
    left: -0%;
  }

  /* off switch */
  label.off:after {
    left: 60%;
  }

  label.off.active:after {
    left: 0%;
  }

  label.on:after {
    left: 0%;
  }

  label.on.active:after {
    left: 60%;
  }
}

/* radio btn group */
fieldset.radio.btn-group {
  padding: 0;

  input {
    display: none;
  }

  label {
    display: inline-block;
    min-width: 54px;
    padding: 0 12px;
    border: 1px solid #aaa;
    line-height: 28px;
    background: #eee;
    color: #555;
    border-radius: 0;
    text-align: center;
    border-right-width: 0px;
    text-transform: uppercase;

    &:last-child {
      border-right-width: 1px;
    }

    &.active {
      background: #690;
      border-color: #5c8b00;
      color: #fff;
    }
  }
}


//
// LEGACY
// -----------------------------------------------------------

// Fix for missing icons
// because of the change from Font Awesome 3 to Font Awesome 4
// -----------------------------------------------------------

.icon-eye-open:before,
.icon-eye:before {
  content:"\f06e";
  font-family: "FontAwesome";
}

.icon-file-add:before {
  content:"\f0f6";
  font-family: "FontAwesome";
}

.icon-cancel:before {
  content:"\f00d";
  font-family: "FontAwesome";
}


// Legacy class for Joomla 2.5
// ---------------------------
.button2-left,
.button2-left div {
  float: left;
}

.button2-right,
.button2-right div {
  float: right;
}

.button2-left {
  margin: 5px 5px 0 0;
}

.button2-right {
  margin: 5px 0 0 5px;
}

.button2-left a,
.button2-right a,
.button2-left span,
.button2-right span {
  background: #eee;
  border: 1px solid #ccc;
  color: @gray-light;
  cursor: pointer;
  display: block;
  float: left;
  padding: 2px 10px;
  border-radius: @border-radius-base;
}

.button2-left span,
.button2-right span {
  color: @gray-light;
  cursor: default;
}

.button2-left .page a,
.button2-right .page a,
.button2-left .page span,
.button2-right .page span {
  padding: 0 6px;
}

.button2-left a:hover,
.button2-right a:hover {
  background: #ccc;
  color: @gray-dark;
  text-decoration: none;
}

.edit.item-page a.modal,
.com_config form a.modal {
  display: inline-block;
  position: inherit;
  width: auto !important;
  top: auto !important;
  overflow: hidden;

  &.btn {
    background: @btn-default-bg;
    text-shadow: none; 
    border-color: @btn-default-border;
    overflow: hidden;
    margin-top:10px;
    display:inline;
        
  }
  

}
.icon-remove {
	padding-right:10px !important
}

.media-preview {
	.icon-eye {
		position:absolute;
		margin-left:10px;
		margin-top:8px
	}
	
	
}
	
	input.input-small {
		text-indent:20px;
		padding-left:30px;
	}
	
	.form-group {
		float:left;
		width:100%;	
}

a.btn.hasTooltip {
		width:35px;
		float:right
	
	
}

// Calendar Button
.controls img.calendar {
  cursor: pointer;
  margin-left: 5px;
}

	

	
	.span6.control-group {
		//width:47% !important
	}
	
	.thumbnails {
		margin-top: 30px;
		margin-left: -20px;
		list-style: none;
		*zoom: 1;
	}
	.thumbnails:before,
	.thumbnails:after {
		display: table;
		content: "";
		line-height: 0;
	}
	.thumbnails:after {
		clear: both;
	}
	.row-fluid .thumbnails {
		margin-left: 0;
	}
	.thumbnails > li {
		float: left !important;
		margin-bottom: 18px!important;
		margin-left: 20px!important;
		height: 100px !important;
		width: 100px !important;
		background: #fff;
	}
	
	
	.thumbnails > li img {
		width: 100%;
	}
	
	.thumbnails > li .small{
		padding: 8px;
		font-size: 90%;
	}
	.thumbnail {
		display: block;
		padding: 4px;
		line-height: 18px;
		border: 1px solid #ddd;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		-webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.055);
		-moz-box-shadow: 0 1px 3px rgba(0,0,0,0.055);
		box-shadow: 0 1px 3px rgba(0,0,0,0.055);
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
	a.thumbnail:hover,
	a.thumbnail:focus {
		border-color: #08c;
		-webkit-box-shadow: 0 1px 4px rgba(0,105,214,0.25);
		-moz-box-shadow: 0 1px 4px rgba(0,105,214,0.25);
		box-shadow: 0 1px 4px rgba(0,105,214,0.25);
	}
	.thumbnail > img {
		display: block;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.thumbnail .caption {
		padding: 9px;
		color: #555;
	}
	
	.well {
		padding:20px
	}	
	
	.span9 {
		width:70%;
		float:left
	}


.bootstrap2 {
	
	.edit {
	
		.btn {
	    	border: 1px solid @btn-default-border;
	    	background-color: @btn-default-bg;
			text-shadow:0;
			position:relative !important;
			margin-top:0 !important;
			top:0 !important;
			left:auto;
			display:inline-block !important;
			
		
		  }	
	
		.input-prepend.input-append .add-on:first-child, .input-prepend.input-append .btn:first-child {
			height:42px;
			width:36px
		}
	}
}


// Front end editing
#modules-form {
	.btn-group {
		float:left;
		margin-right:6px;
		margin-bottom:@gutter
	}
	
	legend {
		clear:both
	}
	
	.control-label {
		width:25%;
		float:left;
		margin-right:20px;
	}
	
	.control-form {
		float:left;
		width:70%
	}
	
	.control-group {
		float:left
	}
	
	
}