/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 
 //
 // Adjust spotlight margin for a few elements
 //
 //
 
 
 // The main margin between the grid rows
 .zen-spotlight {
 	padding:@gutter 0;
 	
 	
 }
  
  
 #topwrap,
 #navwrap {
 	.zen-spotlight {
 		border-bottom:0;
 		padding:@gutter / 3 0 0
 	}
 }
 
 
 #topwrap {
 	&.with-panel {
 		padding-right:40px
 	}
 }
 
 #breadcrumbwrap {
 	.zen-spotlight {
 		padding:@gutter / 3 0 0;
 		padding:10px;
 		
 		@media screen and(max-width:@navcollapse) {
 			padding:10px 0;
 			
 			li,a,span {
 				padding:0;
 				
 			}
 			
 			li:after {
 				margin-right:8px
 			}
 		}
 		
 		.moduletable {
 			margin-bottom:0
 		}
 	}
 }
 
 
 // Make sure the middle column pushes things down
 #midCol {
 	margin-bottom:@gutter
 }
 
 
 // Add buffer on below grid collapse for toprow
 @media screen and (max-width:@nav-collapse) {
 	.select-menu,
 	.offcanvas-menu {
 		#zen-wrap {
 			padding-top:@gutter/2;
 			border-top:50px solid @navbar-default-bg
 		}
	}
}


#footerwrap {
	float:left; 
	width:100%
}