/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-menu-navbar {
	
	border:1px solid @bordercolor;
	border-width:1px 0;
	width:100%;
	
	li {
		
		margin:0;
		
		> a,
		> .zen-menu-heading {
			border-right:1px solid @bordercolor;
			padding:10px 20px;
		}
		
		&:last-child {
			a,
			 .zen-menu-heading {
				border-right:0;
			}
		}
		
		ul {
			 a,
			 .zen-menu-heading {
				.reset();
			}
		}
		
		.zen-menu-caption {
			padding:0
		}
	}
}