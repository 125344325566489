/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */

// Sets up the base scaffold for the main containers

.zen-row {
	margin:0 -@gutter
}

.no-row-margin {
	margin:0;
}
 
.row {
	margin:0;
}

.container .row {
	margin:0 -20px
}
 
.zen-container {
	width:@twidth;
	margin:0 auto;
	padding: 0 !important;
}

.responsive-enabled {
	.zen-container {
		width:@container-width;
		max-width:@twidth;
		
		.zen-container {
			width:100%
		}
	}
}



.tab-content {
	clear:both
}

// Module classes used for psotioning
.top25 {margin-top: 25px}
.top50 {margin-top: 50px}
.top75 {margin-top: 75px}
.top100 {margin-top: 100px}
.top125 {margin-top: 125px}
.top150 {margin-top: 150px}
.top175 {margin-top: 175px}
.top200 {margin-top: 200px}
.top225 {margin-top: 225px}
.top250 {margin-top: 250px}
.top275 {margin-top: 275px}
.top300 {margin-top: 300px}
.top325 {margin-top: 325px}
.top350 {margin-top: 350px}
.top375 {margin-top: 375px}
.top400 {margin-top: 400px}
.top425 {margin-top: 425px} 
.top450 {margin-top: 450px}
.top475 {margin-top: 475px}
.top500 {margin-top: 500px}

