/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 
 /* Back to top
 -----------------------------------------------------------------------------*/

.mean-container {
	
	.mean-nav,
	.off-canvas-body{
		background: @toggle-bg;
		color:@toggle-text;
		float:left;
		width:100%;
		
		p {
			color:inherit
		}
		
		h1,h2,h3,h4,h5,h6 {
			color:@toggle-heading
		}
		
		a {
			color:@toggle-link-color;
			
			&:hover {
				color: @toggle-link-hover-color
			}
		}
		
		.zen-menu-caption {
			display:block;
			margin-left:0;
		}
		
		
		.expand {
			position:absolute;
			z-index:10;
			width:30px;
			background:@body-bg;
			padding:4px 0;
			text-align:center;
			right:30px;
			top:4px;
			font-weight:300;
			font-size:1.2em;
			cursor:pointer;
			
			&:hover {
				background:darken(@body-bg, 3%);
			}
		}
		
		li:last-child {
			margin-bottom:10px
		}
	}
	
	.mean-nav {
		border-bottom:1px solid @border-color;
	}
	
	.off-canvas-body {
		background:darken(@toggle-bg, 4%);
		box-sizing:border-box !important;
		border-top:1px solid #fff;
		border-bottom:1px solid @border-color;
	}
}

.mean-menu-open {
	.mean-nav {
		border-top:1px solid @border-color
	}
}