/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
.make_visible() {
	opacity: 1;
	visibility: visible;
	position: relative; 
	left:0;
	margin:0;
	height:auto;   
}

.reset() {
	margin:0 !important;
	padding:0 !important;
	list-style-type:none !important;
}



@import "base.less";
@import "align-center.less";
@import "align-right.less";
@import "group-items.less";
@import "horizontal.less";
@import "animate-above.less";
@import "animate-below.less";
@import "animate-fade.less";
@import "animate-zoom.less";
@import "tabs.less";
@import "navbar.less";
@import "vertical.less";
@import "menu-module.less";
@import "pills.less";
@import "responsive.less";
@import "select.less";
@import "mean-menu.less";