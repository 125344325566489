/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-menu-zoom {
	
	li {
	
		> ul {
			-webkit-transform:scale(0.4);
			-moz-transform:scale(0.4);
		   	transform:scale(0.4);
		   	
		   	.zen-menu-child {
		   		-webkit-transform:scale(1);
		   		-moz-transform:scale(1);
		   			transform:scale(1);
		   	}
		}
	
		&:hover > ul {
		   	-webkit-transform:scale(1.0);
		    -moz-transform:scale(1.0);
		    transform:scale(1.0);
		}
	}
}



.zen-menu-zoom {
	

	ul {
	
		ul  {
			margin:100px 0 0 0 !important
		}
		
		li:hover ul {
			margin:0 !important
		}
		

	
		.justify {
				
			ul {
				margin:100px 0 0 0 !important;
			}
		
			
			&:hover ul {
				margin:-7px 0 0 0 !important;
				
				ul {
					margin:0 !important
				}
			}
	
			
		}
	}
}