/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-spotlight {
	float:left;
	width:100%;
}	

.breadcrumb {

	margin-bottom:@gutter;
	
	li {
		
		&:after {
			content:'/';
			margin-left:8px;
			color:lighten(@text-color, 4%)
		}
		
		&:last-child {
			&:after {
				content:'';
			}
		}
	}
}


#zen-copyright {
	text-align:right
}

