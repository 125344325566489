/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-menu-horizontal {

	&.zen-menu-no-animation {
		
		ul {
			-webkit-transition: all 0s;
			-moz-transition: all 0s;
			transition: all 0s;
		}

	}
	
	
	// Is menu offscreen
	.zen-menu-offscreen ul {
		margin-left:-100% !important;
		left:-100%;
		
		ul {
			margin-left:-20px !important;
			left:100% !important
		}
	}
	
	.parent:hover {
		z-index:1010
	}
	
	
	
	ul
	{
		width:100%;
		position:relative;
		float:left;
		margin:0;
		padding:0;
		-webkit-transition: all @menu-animation ease-in-out;
		-moz-transition: all @menu-animation ease-in-out;
		transition: all @menu-animation ease-in-out;
		
		&:before,
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		
		
		li
		{
			position:relative;
			z-index:10;
			float:left;
			margin:0;
			padding:0;
			list-style-type:none;
		}
		
		
		a,.zen-menu-heading {
			display:block;
			color:#333;
			float:left;
			text-decoration:none;
			font-weight:700;
			padding:0 15px;
		}
	
		ul
		{
			height:0;
			opacity:0;
			overflow:hidden;
			visibility:hidden;
			position:absolute;
			z-index:98;
			top:100%;
			left:-20px;
			margin-top:-10px;
			background:#fff;
			padding:@gutter;
			min-width: @dropdown_min_width;
			
			li {
				width:100%
			}
	
			ul
			{
				top:0;
				left:100%
			}
		}
		
		.moduletable {
			
			margin-bottom:0;
			
			ul {
				height:auto;
				opacity:1;
				overflow:visible;
				visibility:visible;
				display:block;
				position:inherit;
				left:auto;
				top:auto;
				padding:0;
				margin-bottom:0 !important;
				clear:both;
				
				li {
					padding:0 !important;
					line-height:1em
				}
			}
		}
		
		
		
		.justify {
			position:static;
			
			ul {
				min-width: 0;
				margin-top:-7px !important;
				
				ul {
					margin-top:0 !important
				}
			}
		}
	
		li:hover {
		
			> ul
			{
				height:auto;
				opacity:1;
				visibility:visible;
				top:100%;
				overflow:visible;
				
				.zen-group {
					ul {
						overflow:visible;
						height:auto
					}
				}
			}
		
		
			&.justify > ul {
				//top:85%;
			}
		}
	}
}