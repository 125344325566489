/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 
 // Sets the bg colour for the body and html tags
 html,body {
 	font-weight:@body-font-weight;
 	font-size:@font-size-base;
 }
 
 /* Typography
-----------------------------------------------------------------------------*/
.h1() {
	margin:10px 0;
  font-size: @font-size-base * 3;
  line-height: @line-height-base * 1;
   word-break: break-word;
   
  small {
    font-size: @font-size-base * 2;
  }
}

h1 {
	.h1()
}

.article-title {
	border-bottom:1px solid @border-color;
	margin-top:0;
	line-height: 1.4em;
	margin-bottom:10px
}

h2 {
	margin:10px 0;
  font-size: @font-size-base * 3; 
  line-height: @line-height-base * 1;
   word-break: break-word;
   
  small {
    font-size: @font-size-base * 1.5;
  }
  
  clear:both
}

h3 {
	margin:10px 0;
  font-size: @font-size-base * 1.6; 
  line-height: @line-height-base * 1.4; 
   word-break: break-word;
   
  small {
    font-size: @font-size-base + 2px;
  }
  
  i {margin-right:10px}
}

h4 {
	margin:10px 0;
  font-size: @font-size-base + 2px; 
  line-height: @line-height-base;
   word-break: break-word;
   
  small {
    font-size: @font-size-base;
  }
}

h5 {
	margin:10px 0;
	font-size: @font-size-base + 1px; 
	line-height: @line-height-base; 
	  small {
	    font-size: @font-size-base - 2px;
	  }
}

h6 {
	margin:10px 0;
 	font-size: @font-size-base; 
 	line-height: @line-height-base; 
}

h1,h2,h3,h4,h5,h6 {
	font-weight:@headings-font-weight;
	color:@heading-color
}


ul,legend {
	margin-bottom:30px
}


.small {
	font-size:90%
}

.margins {
	margin:@gutter 0
}




/* Special Headings
-----------------------------------------------------------------------------*/

.headline {
	padding:@gutter 0;
	border-bottom:1px solid @border-color;
	border-top:1px solid @border-color;
	font-size:2em;
	text-align:center
}

p.subheading {
	text-align:center;
	margin-bottom:@gutter * 3;
	font-style:italic
}


/* Paragraph
-----------------------------------------------------------------------------*/
p {
  	margin: 0 0 30px;
  	line-height: @line-height-base * 1.2;
  	font-weight:@body-font-weight;
  	font-size:@font-size-base;

  small, .small {
    font-size: @font-size-small;
    color: @gray-light;
  }
  

  color:@text-color
}


blockquote{
	font-weight: @headings-font-weight;
	line-height: @headings-line-height;
	border:0;
	font-size: @font-size-base * 1.4; 
	border-left:4px solid @border-color;
	margin-left:0;
	padding-left:@gutter;
	
	p {
		font-size:125%;
		line-height: 125%;
	}
}


.badge {
	border-radius:@input-border-radius !important;
}

/* Emphasis and Misc
-----------------------------------------------------------------------------*/

small {
  font-size: 85%; // Ex: 14px base font * 85% = about 12px
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

cite {
  font-style: normal;
}

.muted {
  color: @gray-light;
}

.highlight {
  background-color: #FFC;
  font-weight: bold;
  padding: 1px 4px;
}


code {
	display: block;
	max-height: 400px;
	overflow: scroll;
	margin: 20px 0;
}


/* Images
-----------------------------------------------------------------------------*/


img {
  max-width: 100%;
  height: auto;
  
}


main .thumbnail {
	border-radius:0
}


/* Links
-----------------------------------------------------------------------------*/

a {
	color: @link-color;
	text-decoration:none;
	.transition();
	
	&:hover {
		color:@link-hover-color
	}
}



// Top Links
#topwrap {
	
	a {
		color:@top-link-color;
		
		&:hover {
			color:@top-link-hover-color;
		}
	}
}


// Footer Links
#footerwrap {
	
	a {
		color:@footer-link-color;
		
		&:hover {
			color:@footer-link-hover-color;
		}
	}
}


/* Dividers
-----------------------------------------------------------------------------*/


.divider,hr {
	border-bottom:1px solid @border-color;
}

hr {
	margin: @gutter 0
}



/* Zebra styling
-----------------------------------------------------------------------------*/


.list-striped,.table-striped {
	li,tr{
		
		padding:10px 20px !important;
		
		&:nth-child(2n + 1) {
			background:lighten(@border-color,8%)
		}
	}
	
	th {
		padding:10px 20px !important;
		background:lighten(@border-color,3%)
	}
	
	
	td {
		
		padding:10px 20px !important;

	}
	
}

.list-striped {
	.simplelist
}




/* Buffer on zen icons
-----------------------------------------------------------------------------*/

.zen-icon {
	margin-right:8px
}

.tags {
	
	.zen-icon {
		margin-right:0
	}
	
}




/* Anvil
-----------------------------------------------------------------------------*/
.anvil,
#mainWrap .anvil{
position: relative;
width: 80px;
height: 80px;
margin:0;
text-align: right;
font: 30px/100px Arial, sans-serif;
color: white;
border:0 !important;
padding-right:10px
}


.anvil::after {
content: "";
position: absolute;
top: 0;
left: 0;
border-width: 0 0 80px 80px;
border-style: solid;
border-color: transparent @body-bg;
}

.anvil {
	&.primary1 {
		background:@primary
	}
	&.primary2 {
		background:@primary2
	}
	&.primary3 {
		background:@primary3
	}
	&.secondary1 {
		background:@secondary
	}
	&.secondary2 {
		background:@secondary2
	}
	&.secondary3 {
		background:@secondary3
	}
}




/* Large Rounded numbers
-----------------------------------------------------------------------------*/
.rounded-big  {
	.border-radius(100px);
	padding:10px;
	font-size: 2em;
	display:inline-block;
	width: 80px;
	text-align: center;
	line-height: 60px;
	color:#fff;
	
	
	&.primary1 {
		background:@primary
	}
	&.primary2 {
		background:@primary2
	}
	&.primary3 {
		background:@primary3
	}
	&.secondary1 {
		background:@secondary
	}
	&.secondary2 {
		background:@secondary2
	}
	&.secondary3 {
		background:@secondary3
	}
	
}




// Pre Tags
pre,code {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  border: 1px solid @border-color;
  padding: @gutter;
  background: #fafafa;
  font-family: monospace;
  position: relative;
	border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.05) inset;
  
  p {
  	margin:0
  }
}

pre span.code-title {
  position: absolute;
  display: block;
  background: #FFF;
  top: 0;
  right: 0;
  border-radius: 0px;
  padding: 3px 5px;
  font-size: 12px;
  box-shadow: -2px 2px 1px rgba(0,0,0,0.1);
  color: #999;
}

