/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 

// 
// Menu Style
// 

.zen-menu-btn-wrap {
	

	
	a{
		color:@navbar-default-link-color;
		text-transform:uppercase;
			padding:4px 0 12px;
			float:left;
			width:100%;
	}
	
	:before {
		font-size:1.2em
	}
}


#menu { 
	background:@navbar-default-bg;
	
	padding:0 20px;
	border:1px solid @border-color;
	border-width:1px 0;
	float:left;
	width:100%;
	min-height:(@navbar-height - 20);
	
	&.zen-menu-center {
		height:(@navbar-height - 20);
		
		a, .zen-menu-heading {
			padding-top:10px;
		}
		
		li {
			display:inline-table
		}
		
		li.with-caption a {
			margin-top:4px;
			display:inline
		}
		
	}
	
	
	.zen-menu-caption {
		margin-top:-18px;
		margin-bottom:8px;
		font-weight:300;
		line-height:1em;
		font-size:11px;
		font-style:italic;
		color:@navbar-text-color
	}
	
	.with-caption {
		margin-top:-4px;
	}
	
	ul ul {
		.with-caption {
			margin-top:0;
		}
	}
	
	
	// Top Level Items
	ul {
	
		margin-left:0;
	
		> li {
		
			line-height: @navbar-height /2;
			
			a,.zen-menu-heading {
				color:@navbar-default-link-color;
				font-weight: @navfontweight;
				text-transform:uppercase;
				padding:10px 20px 4px;
				background:none;
				text-shadow:none;
				box-shadow:none;
				
				&:hover {
					color:@navbar-default-link-hover-color
				}
				
				&:before {
					border-color: @navbar-default-link-color transparent transparent transparent;
				}
			}
			
			
			&.active {
			
				> a,
				> .zen-menu-heading {
					color:@navbar-default-link-active-color;
					background:@navbar-default-link-active-bg
				}
			}
		}
		

		
		// First Level Dropdown Items
		ul {
			
			-webkit-box-shadow: 0 0 4px rgba(0,0,0,0.2);
			-moz-box-shadow: 0 4px 4px rgba(0,0,0,0.08);
			box-shadow: 0 4px 4px rgba(0,0,0,0.08);
			background:@dropdown-bg;
			padding:20px;
			border:1px solid @border-color;
			
			margin: 0 0 0 20px;
			
			@media screen and (max-width:@nav-collapse) {
				width:auto !important
			}
			
			.zen-menu-caption {
				margin-top:0;
			}
		
			> li {
		
				line-height: 1em;
			
				
				a,.zen-menu-heading {
					color: @dropdown-link-color;
					padding:4px 0;
					line-height:2em;
					margin-bottom:0;
					display:block;
					border-bottom:1px solid @border-color;
					
					&:hover {
						color: @dropdown-link-hover-color
					}
				}
				
				&.active {
				
					> a,
					> .zen-menu-heading {
						color:@dropdown-link-active-color;
						background: @dropdown-link-active-bg
					}
				}
				
				&:last-child {
						
						a,.zen-menu-heading {
							border-bottom:0;
						}
				}
			}
			
			.zen-group {
				a,.zen-menu-heading {
					border-bottom:0
				}
			}
			
			ul {
				top:0;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				min-width:0px
			}
			
			
			// Colours for non-links in dropdown menu
			p,ul,h1,h2,h3,h4,h5,h6,
			.zen-menu-caption {
				
				color:  @dropdown-header-color
			}
			
			.moduletable {
				width:100%;
				float:left;
				
				ul {
					border:0;
					padding:0;
					margin:0;
					li {
						a {
							margin:0;
							padding:4px 0
						}
					}
				}
			}
			
			
			input {
				text-indent:12px;
				width:100%;
			}
			
			input[type="checkbox"], 
			input[type="radio"] {
				width:auto 
			}
		}
		
		.cols-2 > li{
			width:45% !important;
			float:left;
			margin-right:5%;
			
			li {
				width:100%!important;
			}
		}
		
		.cols-3 > li{
			width:31% !important;
			float:left;
			margin-right:2.3%;
			
			li {
				width:100%!important;
			}
		}
		
		.cols-4 > li{
			width:21% !important;
			float:left;
			margin-right:4%;
			
			li {
				width:100%!important;
			}
		}
	}
}

.offcanvas-menu {
	#menu.zen-menu-right {
		padding-right: 80px
	}
}

#navwrap.fixed {
	position:fixed;
  	top:0px;
  	right:0px;
  	left:0;
  	z-index:10;  	
  	-webkit-transform: translateZ(0);
	background:@body-bg;
	
	.zen-spotlight {
		margin-top:0;
		padding-top:0
	}
}

.nav-tabs {
	border-bottom:0;
	.flat-list;
	float:left;
	width:100%;
	
	li {
		
		a {
			.btn();
		}
		
		&.active {
			
			a {
				.btn-active();
			}
		}
	}
}


.accordion {

	ul {
		ul {
			display:none;
			
			ul {
				//display:table !important
			}
		}
	}
	
	li.parent {
		
		cursor:pointer;
		
		.expand {
			position:absolute;
			margin-top:4px;
			cursor:pointer;
			right:0;
		
			&:before {
				float:right
			}
		}
	}
}


.sidebar {
	
	ul {
		
		ul {
			margin-bottom:0
		}
		
		li {
		
			a {
				
				&:hover {
					background:none
				}
			}
		}
	}
}


// One page menu
#menu {
	#onepage {
		li a {
			padding-bottom:12px
		}	
	}
}


