/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */


// -------------------------------------------------------
// Breakpoints
// -------------------------------------------------------

	
// Remove the background on the menus if using select or toggle menu
@media screen and (max-width:@nav-collapse) {
	.select-menu {
		#menu {
			background:none;
			padding:@gutter 0 @gutter * 2;
		}
	}
	

	.toggle-menu,
	.offcanvas-menu {
		#menu {
			background:none;
			border-top:0;
			padding:10px;
			height:0;
			min-height:0
		}
	}	
	
	.stacked-menu,
	.default-menu {
		padding-top:50px
	}
}


.responsive-enabled {	
	@media screen and (max-width:@gridcollapse) {
	
		#tabwrap {
			margin-top:0;
			
			.zen-spotlight {
				padding-top:0;
			}
		}
		
		.nav-tabs {
			li {
								
				a {
					border:1px solid @border-color !important;
				}
				
				&.active {
					a {
						background:@primary !important;
						color:#fff !important
					}
				}
			}
		}
	}
}