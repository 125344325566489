/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .padding () {
 	padding:10px 3%;
 }
 
 .padding-row () {
 	padding: 20px 0 0px;
 }
 
 .padding-row-bottom() {
 	padding: 0 0 30px;
 }
 
 .margin() {margin: 0 0 30px}
 
 
 
 // Display Items
 .dividerstyle () {
 	border-bottom:4px solid lighten(@minorborderColor, 40%);
 }
 
 .dividertop () {
 	background-position: right top
 }
 