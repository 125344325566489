/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */


	
.transition() {
	-webkit-transition:all 0.2s ease-in;  
	  -moz-transition:all 0.2s ease-in;  
 	 -o-transition:all 0.2s ease-in;  
 	 transition:all 0.2s ease-in;
} 


// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
          transition: @transition;
}