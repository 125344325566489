/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 /* Logo
 -----------------------------------------------------------------------------*/

#logo {
	
	h1,h2,h3,h4,h5,h6 {
		font-weight: @logo-font-weight;
		font-size: @logo-font-size;
		line-height: @logo-line-height;
		color:@logo-color;
		margin:0;
		
		a {
			color:@logo-color;
			
			&:hover {
				color:@logo-hover-color
			}
		}
	}
	
	p {
		margin-bottom:0
	}
}

#logowrap {
	
	.zen-spotlight {
		padding-top:@gutter;
		padding-bottom:0;
		border:0;
		
		@media screen and (max-width:@nav-collapse) {
			padding-top:0
		}
	}
	
	@media screen and (max-width:@nav-collapse) {
		.moduletable {
			margin-bottom:0;
		}
	}
	
	
	@media screen and (max-width:@gridcollapse) {
		.moduletable {
			margin-bottom:0;
		}
	}
	
	
	#search {
		
		form {
			padding-top:30px;
			float:right;
			width:100%
		}	 
		
		input {
			width:72%;
			float:left
		}
		
		button {
			width:25%;
			float:right
		}	 
	}
}

