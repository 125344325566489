/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 
.rgbaColorIn(@color, @opacity : @opacity-value){
  @result: rgba( red(@color), green(@color), blue(@color), @opacity );
}


// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
.clearfix() {

	clear:both;
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}



.totallyhidden() {
	display:none!important 
}

.visuallyhidden() {
	position: absolute; 
	overflow: hidden; 
	clip: rect(0 0 0 0); 
	height: 1px; width: 1px; 
	margin: -1px; padding: 0; border: 0; 
}
   