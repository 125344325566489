/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */


/*------------------------------------*\
	FORMS
\*------------------------------------*/


// Fieldsets
fieldset{
	margin:0;
	max-width: 100%;
	border:0;
	padding:0;
}


fieldset legend {
	font-weight: bold;
	font-size: 1.2em;
	width:100%;
	padding-bottom:8px;
	border-bottom:1px solid @border-color
}


// Labels
label{
	display: block;
	cursor:pointer;
	
}


// Inputs
input,
textarea{
	font-family:inherit;
	font-size:1em;
	line-height:2em;
	padding:6px 12px;
	background: @input-bg;
	color:@input-color;
	border:1px solid @input-border;
}

input:hover,
textarea:hover {
	background:lighten(@border-color, 5%);
}



input.button {
	width: auto;
	-webkit-appearance:none
}




button {
	background:@gray-lighter;
	border:0;
}

label {
	font-weight:normal;
	margin-bottom:4px;
}

.control-label {
	margin-bottom:0
}


.simple-list {
	.simplelist();
}

.flat-list {
	.flatlist();
	margin-bottom:@gutter
}


// Buttons
button {
	padding:10px;
}


// Remove Webkit Autofill bg colour
input:-webkit-autofill {
   
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    
    &:hover {
    	
    	-webkit-box-shadow: 0 0 0px 1000px lighten(@border-color, 5%) inset;
    }
}



// Control Groups
.control-label {
	float: left;
	padding-top: 5px;
	text-align: right;
}

.control-group {
	display:table;
	width:100%;
	margin-bottom:@gutter;
	
	.optional {
		font-size:85%
	}
}


// Definition Lists
dl {
	clear:both;
	margin:@gutter 0;
}


input,select {
	min-height: @input-min-height;
	border-radius:@input-border-radius !important;
	border: 1px solid @border-color  !important;
	box-shadow:none !important
}

input[type="checkbox"], input[type="radio"] {
	min-height: 0;
}

textarea {
	border-radius:@input-border-radius !important;
}

.table-bordered {
	border-radius:@input-border-radius !important;
}


// Forms
.form-control {
	height:auto
}

.form-group {
	margin-bottom:@gutter;
}

// Fix for chozen z-index
