/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 
 /* Back to top
 -----------------------------------------------------------------------------*/
 #toTop{
 	position:fixed;
 	z-index:100;
 	right:0px;
 	bottom:0px;
 	display:none;
 	cursor:pointer;
 	width:@gutter * 2;
 	text-align:center;
 	height:@gutter * 2;
 	font-size:1.4em;
 	line-height:@gutter * 2;
 	background:@secondary;
 	color:#fff;
 	
 	span {
 		color:#fff;
 		margin-right:0;
 	}
 	
 	[class^="icon-"], [class*=" icon-"] {
 		
 		line-height:@gutter * 1.5;
 	}
 	
 	&:hover {
 		background: #333;
 		
 		a {
 			color:#fff;
 				&:hover {
 					color:#fff
 				}
 			}
 	}
 }
 
 #toTopText {
 	white-space: nowrap;
 	overflow: hidden;
 	text-indent:100%;
 	display:block
 }
 