/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 

// Basic margin on module titles
.zen-module-title {
	margin-bottom:10px;
	
	
	h3 {
		// Remove the margin on the h3
		margin:0;
		
		// Make moduletables uppercase
		text-transform:uppercase;
	}
}



/* Primary1, Primary2, Primary3, Secondary1, Secondary2, Secondary3
-----------------------------------------------------------------------------*/
.moduletable {
	
	clear:both;
	margin-bottom:@gutter;
	
	&.primary1 {
		.zen-module-title {
			border-left:4px solid @primary;
			padding:0 0 0 20px
		}
	}
	&.primary2 {
		.zen-module-title {
			border-left:4px solid @primary2;
			padding:0px 0 0px 20px
		}

	}
	&.primary3 {

		.zen-module-title {
			border-left:4px solid @primary3;
			padding:0px 0 0px 20px
		}

	}
	&.secondary1 {
		
		.zen-module-title {
			border-left:4px solid @secondary;
			padding:0px 0 0px 20px
		}

	}
	&.secondary2 {
		.zen-module-title {
			border-left:4px solid @secondary2;
			padding:0px 0 0px 20px
		}

	}
	&.secondary3 {
		.zen-module-title {
			border-left:4px solid @secondary3;
			padding:0px 0 0px 20px
		}

	}
	
}




// Border Classes

.moduletable.border {

	padding:@gutter;
	border:1px solid @border-color;

	&.primary1 {
		background:none;
		border-color: @primary;
		
		p,h3 {color:@text-color}
	}
	&.primary2 {
		background:none;
		border-color:  @primary2;
		
		
		p,h3 {color:@text-color}
	}
	&.primary3 {
		background:none;
		border-color:  @primary3;
		
		
		p,h3 {color:@text-color}
	}
	&.secondary1 {
		background:none;
		border-color:  @secondary;
		
		
		p,h3 {color:@text-color}
	}
	&.secondary2 {
		background:none;
		border-color:  @secondary2;
		
		
		p,h3 {color:@text-color}
	}
	&.secondary3 {
		background:none;
		border-color:  @secondary3;
		
		
		p,h3 {color:@text-color}
	}
	
}



/* Dark
-----------------------------------------------------------------------------*/
.moduletable.dark {
	background: #555;
	border:1px solid #111;
	color: #fff ;
	padding:20px;
	
	p,h3 {
		color:#fff
	}
}



/* Light
-----------------------------------------------------------------------------*/
.moduletable.light {
	background: #fff;
	border:1px solid #eee;
	color: #fff ;
	padding:20px;
	
	p,h3 {
		color:#333
	}

}


/* Heading Alignments
-----------------------------------------------------------------------------*/
.right-heading {
	
	h3 {
		text-align: right;
	}
	
}


.center-heading {
	
	h3 {
		text-align: center;
	}
	
}


.small-heading {
	
	h3 {
		font-size:100%
	}
	
}





/* Centered
-----------------------------------------------------------------------------*/
.moduletable.centered {
	h1,h2,h3,h4,h5,h6,p{
		text-align:center;
	}
}



/* Shadow Styling
-----------------------------------------------------------------------------*/
.moduletable.shadow,
.inset.shadow .zen-module-body,
div.shadow {
	box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.15);
	padding:@gutter

}

.moduletable.shadow2,
.inset.shadow2 .zen-module-body,
div.shadow2 {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	padding:@gutter

}

.inset {
	.zen-module-body {
		background:darken(@body-bg,4%);
		color:@text-color;
		border:1px solid @border-color;
		padding:20px 20px 0;
	}	
}

.moduletable.shadow.inset,
.moduletable.shadow2.inset  {
	padding:0;
	box-shadow:none
}




/* Image Border
-----------------------------------------------------------------------------*/
img.border,
.zenborder img {
	.img-border()
}








 

/* Corner
-----------------------------------------------------------------------------*/
.corner{
	position:relative;
	margin:0 auto @gutter;
	border: 1px solid @border-color;
	border-width:0 0 1px 0;
	
	
	&.primary1,
	&.primary2,
	&.primary3,
	&.secondary1,
	&.secondary2,
	&.secondary3 {
	
		padding:20px;
		background:darken(@body-bg,4%);
		
		
		.zen-module-title{
			background:none;
			padding:0 !important;
			border:0;
		}
	}

	&::after {
		content:"";
	   	position:absolute;
	   	top:0;
	   	right:0px;
	   	border-width:0 16px 16px 0;
	   	border-style:solid;
	   	border-color:#658E15 @body-bg;
	}
}

.corner.primary1::after {   border-color:darken(@primary, 15%) @body-bg}
.corner.primary2::after {   border-color:@primary  @body-bg}
.corner.primary3::after {   border-color:darken(@primary, 30%) @body-bg}
.corner.secondary1::after {   border-color:darken(@secondary, 15%)  @body-bg}
.corner.secondary2::after {   border-color:@secondary  @body-bg}
.corner.secondary3::after {   border-color:darken(@secondary, 30%)  @body-bg}


/* Icons
-----------------------------------------------------------------------------*/
.moduletable.zen-icon {
	position: relative;
	float:left;
	width:100% !important;
	

	&:before {
		font-size: 2em;opacity: 0.3;position:absolute;z-index:1;right:20px;top:10px
	}

	&small:before {
		font-size: 1em;
	}

	&.medium:before {
		font-size: 4em;
	}

	&.large:before {
		font-size: 6em;
	}

	&.top:before {
		top: 6px
	}

	&.bottom:before {
		bottom: 6px;top: auto;
	}
}

.moduletable.icon {
height: auto;
width: auto;
}



/* Large icons
-----------------------------------------------------------------------------*/
.largeicons {

	i{
		height:100px;width:100px;
		border:4px solid #333;
		border-radius:200px;
		display:block;
		margin:0 auto;
		text-align:center;
	
		&:before{
			font-size:2em;
			color:#333;
			position:absolute;
			width:100px;
			text-align:center;
			display:block;
			margin-left:0px;
			margin-top:27px;
		}
		
	
			
		&:hover:before {
			color:@secondary;
		}
		
		&:hover {
			border:4px solid @secondary
		}
		
	}

}



/* Hot new etc
-----------------------------------------------------------------------------*/
.hot, .new  {
	position: relative;
	z-index: 1;

	h3:after,
	h3:after{
	    position: absolute;
	    z-index: 20;
	    padding: 0px 4px;
	    font-size: 10px;
	    top: -6px;
	    right: 0px;
	    font-style: italic;
	    background: #333;
	    color: #fff;
	    line-height:1.4
    }
    
  
}

.sidebar {
	.hot, .new  {
	
		h3:after,
		h3:after{
		
			top:-10px
		}
	}
}

.hot h3:after{
    content: "Hot";
    background: @primary;
}


.new h3:after{
    content: "New";
    background: @secondary;
}


.primary1 h3:after {
	background: @primary3 !important;
}

.primary1 h3:after {
	background:  lighten(@primary, 30%);
}

.primary2 h3:after {
	background:  @primary;
}

.primary3 h3:after {
	background:  darken(@primary, 30%);
}

.secondary1 h3:after {
	background:  lighten(@secondary, 10%);
}

.secondary2 h3:after {
	background:  @secondary;
}

.secondary3 h3:after {
	background:  darken(@secondary, 30%);
}



// Generic Classes for modules
// Class to remove padding
.moduletable {
	&.nopadding {
		padding:0
	}


	// Make all the icons large
	&.large {
	
		.zen-icon:before {
			font-size:6em 
		}
	}


	// Rounded corners
	&.rounded {
		.border-radius(4px);
		background:darken(@body-bg,4%);
		padding:20px;
		
		p {
			margin-bottom:0
		}
	}
}

