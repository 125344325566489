/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 
// Lists
.flatlist () {

	margin:0;
	
	li {
		list-style-type:none;
		float:left;
		margin-right:10px;
	}

}


.last-child() {
	li:last-child,
	li:last-child a {
		border-bottom:0;
	}
}


.lastchild(){
	li:last-child,
	li:last-child a {
		border-bottom:0;
	}
}




.first-child() {
	li:first-child,
	li:first-child a {
		border-top:0;
	}
}

.firstchild() {
	li:first-child,
	li:first-child a {
		border-top:0;
	}
}


 .simplelist() {
   
   	margin: 0;
   	
   	li {
   		list-style-type: none;
   		padding: 4px 0;
   	li {background:none !important}
   
   	}
   	
   	ol {
   		list-style-type:decimal;
   	}
  }
  