// Base Class Definition
// -------------------------

.@{fa-css-prefix}:before,
.icon-envelope:before,
.icon-print:before,
.icon-calendar:before,
.icon-remove:before,
.icon-edit:before,
.icon-folder-2:before,
.icon-cancel:before { 
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
