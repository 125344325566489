/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 // Bootstrap Fixes
.container {
	max-width:100%
}

main .container {
	padding:0;
	
	width:100%
}


// Fix for modal

div.modal {
	display:none;
	
	&.in {
		display:block
	}
}



// Inputs
.bootstrap2 {
	input.uneditable-input, span.uneditable-input {
		box-sizing: content-box;
	}
}

// reset checkbox and radio width
.bootstrap2,
.bootstrap3 {
	input[type="radio"],
	input[type="checkbox"] {
	  width:auto
	}
}


// Nav
.navbar-nav,
.navbar-collapse,
.collapse,
.navbar-responsive-collapse {
	clear:none !important
}



.nav-tabs {

	.dropdown-menu {
		
		border:0;
		padding:0;
		
		li,a {
			width:100%;
			margin:0;
		}
		
		.divider {
			margin:0;
		}
	}
}


.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
	.btn-active();
}


// ul reset 
.nav-pills,.list-group,ul.breadcrumb {
	margin-left:0;
}

.list-unstyled {
	margin:@gutter * 2 0
}


// Breadcumb
.breadcrumb > li + li:before {
	content:""
}


// Wells
.well-lg {padding:@gutter * 2 !important}
.well-sm {padding:@gutter / 2 !important}



// Bootstrap 2

// Navbar
.navbar-search {
	margin-top:@navbar-height / 10;
}

.navbar-inverse {
	input {
		border-color:rgba(0,0,0,0.3) !important
	}
}

// Breadcrumb
// Only applied tpo main because the template uses the same class but different style
main {
	ul.breadcrumb {

		float:left;
		width:100%;
		margin:0;
	
		.divider {
			clear:none;
			display:inline;
			float:right;
			border:0
		}
	
		li {
			float:left
		}
	
		li:after {
			content:""
		}
	}
}


