/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */

// -------------------------------------------------------
//  Base styles
// -------------------------------------------------------


/* Reset the margin on menu items - Not really sure why this is necessary */
ul, menu, dir,ol{
padding-left: 0;
-webkit-margin-before: 0em;
-webkit-margin-after: 0em;
-webkit-margin-start: 0px;
-webkit-margin-end: 0px;
-webkit-padding-start: 0px;
}

div,ul,li{
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box;
  }
  
  
  .span12 {margin: 0 !important}
  .hidden {display: none !important}
  

/* Reset DD things in Joomla */
dd {padding: 0;margin: 0}


/* Indent on ul */
ul,ol {
	margin-left: 16px;
}

fieldset {

	min-width:0
}


/* Sidebar margin ul reset */
.items-more ol {
	margin-left: 0;
	padding: 0;
}


/* Dont like Hr styling */
hr {
	border: 0;
}


/* Puts default margin on content items */
article{
	margin:0;
	padding:0
}


* {
	box-sizing:border-box
}



// Bootstrap resets
.btn-group a {
	margin-right:8px
}

.btn-group > .dropdown-toggle,
.btn-group > .btn:first-child,
.btn-group > .btn:last-child {
	.border-radius(0)
}

#system-message {
	margin:0 !important
}

legend {
	margin:0
}

iframe {
	max-width:100%
}

figure {
	margin:0
}
