/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
.sidebar {


	// Basic adjustments for sidebar modueltables
	.moduletable {
	
		margin-bottom:@gutter;
		
		ul,
		ul.unstyled {
			
			.simplelist();
			
			margin-top:10px;
			
			li {
				padding:4px 0;
			}
		
			ul {
		
				margin-left:16px !important;
				margin-top:4px;
				
				
				li {
					list-style-type:disc;
					padding:4px 0;
				}
			}
		}
	}
	
	
	// Basic styling for captions in the sidebar
	.zen-menu-caption {
		display:block;
		font-style:italic;
		font-size:90%;
		color:@gray;
		line-height:1.2em
	}
	
	
	// Some adjustments for menu headings etc
	.nav-header,
	.zen-menu-heading {
	  display: block !important;
	  padding: 4px 0 !important;
	  font-size: 100% !important;
	  font-weight: inherit !important;
	  line-height: 1em !important;
	  color: @text-color !important;
	  text-shadow: none !important;
	  text-transform: none !important;
	}
	
	
	// Reset some spurious padding
	.nav > li > a {
		padding-left:0
	}
	
	
	
	// Basic treatment for sidebar menus
	ul.menu {

		margin-bottom:@gutter;
		
		ul {
			margin-left:16px
		}
	}
	
	
	// Just to make sure if the menu outputs a border class
	li.divider {
		border:0
	}
	
}