/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-menu-below {
	

	ul {
	
		ul  {
			margin:100px 0 0 0 !important
		}
		
		li:hover ul {
			margin:0 !important
		}
		

	
		.justify {
				
			ul {
				margin:100px 0 0 0 !important;
			}
		
			
			&:hover ul {
				margin:-7px 0 0 0 !important;
				
				ul {
					margin:0 !important
				}
			}
	
			
		}
	}
}