/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */

/* Reusable column setup */
	
	.mixin-col {
	     border:0px solid rgba(0,0,0,0);
	     float:left;
	     -webkit-box-sizing:border-box;
	     -moz-box-sizing:border-box;
	     box-sizing:border-box;
	     -moz-background-clip:padding-box !important;
	     -webkit-background-clip:padding-box !important;
	     background-clip:padding-box !important;
	}
	

	.stacked() {
		.mixin-span_first;
		margin-left:0;
		padding:0;
		width:100%
	}
	
	
	.zg-col{
	     .mixin-col;
	}
	
	
	
	.mixin-span(@num, @gutter-pc, @gutter_px, @padding, @max_columns) {
	    @one_col: (100% - (@gutter-pc * (@max_columns - 1))) / @max_columns;
	    width:(@one_col * @num) + (@gutter-pc * (@num - 1));
	    border-left-width:@gutter_px;
	    padding:@padding;
	    margin-left:@gutter-pc;
	}
	
	
	.pushcol(@num, @gutter-pc) {
		@cols: 100%/12;
		@offset: @num;
		@gutter: @gutter-pc * (0.08 * @offset);
		left:((@cols)* @offset) + @gutter;
	}
	
	
	.pullcol(@num, @gutter-pc) {
		@cols: 100%/12;
		@gutter: @gutter-pc * (0.08 * @num);
		left:-(((@cols)* @num) + @gutter);
	}
	
	
	.pad-push-col(@num, @gutter-pc) {
		@cols: 100%/12;
		@offset: @num;
		@gutter: @gutter-pc * (0.08 * @offset);
		padding-left:((@cols)* @offset) + @gutter;
	}
	
	
	.pad-pull-col(@num, @gutter-pc) {
		@cols: 100%/12;
		@offset: @num;
		@gutter: @gutter-pc * (0.08 * @offset);
		padding-right:((@cols)* @offset) + @gutter;
	}
	
	
	.mixin-span_first,
	.no-margin {
	    margin-left:0;
	}
	
	
	// Legacy class for zentools and JB Type syntax
	.zenlast {
		float:right !important;
		margin-left:0!important;
	}


	 // Sets all columns to full width
   // Widths get instantiated at browsers 
   // Beyond the global collapse point
  	.zg-col {
  	    .stacked();
  	}
   
   
   
   
	@media screen and (min-width: @gridcollapse) {
        
        .zg-col {
            margin-left:0;
            
        }
        
        /* --- Gridpak variables ---*/ 
        @max_columns: 12;
        @padding:0;
        @gutter_px: 0;
       
        

		@one_col: 100% / @max_columns;
        
        .zen-row .zg-col:first-child {
            .mixin-span_first;
        }
       
        
        /*
        Add your semantic classnames in alongside their corresponding spans here. e.g.
        
        */
        .zg-col-1 {
            .mixin-span(1, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-2,
        .one-sixth {
            .mixin-span(2, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-3,
        .one-quarter {
            .mixin-span(3, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-4,
        .one-third {
            .mixin-span(4, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-5 {
            .mixin-span(5, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-6,
        .one-half {
            .mixin-span(6, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-7 {
            .mixin-span(7, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-8,
        .two-thirds {
            .mixin-span(8, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-9,
        .three-quarters {
            .mixin-span(9, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-10 {
            .mixin-span(10, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-11 {
            .mixin-span(11, @gutter-pc, @gutter_px, @padding, @max_columns);
        }
        .zg-col-12,
        .one-whole {
            .stacked()
        }
        
        
        
        .zen-push,
        .zen-pull {
        		position:relative;
        		z-index:1;
        }
        
        
        .zen-push-11 {.pushcol(11, @gutter-pc)}	
        .zen-push-10 {.pushcol(10, @gutter-pc)}	
        .zen-push-9 {.pushcol(9, @gutter-pc)}	
        .zen-push-8 {.pushcol(8, @gutter-pc)}	
        .zen-push-7 {.pushcol(7, @gutter-pc)}	
        .zen-push-6 {.pushcol(6, @gutter-pc)}	
        .zen-push-5 {.pushcol(5, @gutter-pc)}	
        .zen-push-4 {.pushcol(4, @gutter-pc)}	
        .zen-push-3 {.pushcol(3, @gutter-pc)}	
        .zen-push-2 {.pushcol(2, @gutter-pc)}	
        .zen-push-1 {.pushcol(1, @gutter-pc)}	
        
        .zen-pull-11 {.pullcol(11, @gutter-pc)}
        .zen-pull-10 {.pullcol(10, @gutter-pc)}
        .zen-pull-9 {.pullcol(9, @gutter-pc)}
        .zen-pull-8 {.pullcol(8, @gutter-pc)}
        .zen-pull-7 {.pullcol(7, @gutter-pc)}
        .zen-pull-6 {.pullcol(6, @gutter-pc)}
        .zen-pull-5 {.pullcol(5, @gutter-pc)}
        .zen-pull-4 {.pullcol(4, @gutter-pc)}
        .zen-pull-3 {.pullcol(3, @gutter-pc)}
        .zen-pull-2 {.pullcol(2, @gutter-pc)}
        .zen-pull-1 {.pullcol(1, @gutter-pc)}
        
       	
       	.zen-pad-push-11 {.pad-push-col(11, @gutter-pc)}
        .zen-pad-push-10 {.pad-push-col(10, @gutter-pc)}
        .zen-pad-push-9 {.pad-push-col(9, @gutter-pc)}
        .zen-pad-push-8 {.pad-push-col(8, @gutter-pc)}
        .zen-pad-push-7 {.pad-push-col(7, @gutter-pc)}
        .zen-pad-push-6 {.pad-push-col(6, @gutter-pc)}
        .zen-pad-push-5 {.pad-push-col(5, @gutter-pc)}
        .zen-pad-push-4 {.pad-push-col(4, @gutter-pc)}
        .zen-pad-push-3 {.pad-push-col(3, @gutter-pc)}
        .zen-pad-push-2 {.pad-push-col(2, @gutter-pc)}
        .zen-pad-push-1 {.pad-push-col(1, @gutter-pc)}
        
        
        .zen-pad-pull-11 {.pad-pull-col(11, @gutter-pc)}
        .zen-pad-pull-10 {.pad-pull-col(10, @gutter-pc)}
        .zen-pad-pull-9 {.pad-pull-col(9, @gutter-pc)}
        .zen-pad-pull-8 {.pad-pull-col(8, @gutter-pc)}
        .zen-pad-pull-7 {.pad-pull-col(7, @gutter-pc)}
        .zen-pad-pull-6 {.pad-pull-col(6, @gutter-pc)}
        .zen-pad-pull-5 {.pad-pull-col(5, @gutter-pc)}
        .zen-pad-pull-4 {.pad-pull-col(4, @gutter-pc)}
        .zen-pad-pull-3 {.pad-pull-col(3, @gutter-pc)}
        .zen-pad-pull-2 {.pad-pull-col(2, @gutter-pc)}
        .zen-pad-pull-1 {.pad-pull-col(1, @gutter-pc)}
     }
    
    
    
    .responsive-enabled {
	    @media screen and (max-width: @tablet-max-width) {
	     		
	    		.stack-tablets {
	    		
	    			.zg-col{
	    				
	    				 .stacked() 
	    			}
	    		}
	    	}
	     	
	     	
	    	@media screen and (max-width: @phone-max-width) {
	     		
	    		.stack-phones {
	    			.zg-col {
	    				 .stacked()
	    			}
	    		}
	    	}
    }
    	
   // No Change for items in the layout that have the classes no-change-tablets and no-change-phones
  .responsive-disabled,
  .no-change-phones,
  .no-change-tablets { 
	   .zg-col {
	               margin-left:0;
	               
	           }
	           
	           /* --- Gridpak variables ---*/ 
	           @max_columns: 12;
	           @padding:0;
	           @gutter_px: 0;
	          
	           
	   
	   		@one_col: 100% / @max_columns;
	           
	           .zen-row .zg-col:first-child {
	               .mixin-span_first;
	           }
	          
	           
	           /*
	           Add your semantic classnames in alongside their corresponding spans here. e.g.
	           
	           */
	           .zg-col-1 {
	               .mixin-span(1, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-2,
	           .one-sixth {
	               .mixin-span(2, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-3,
	           .one-quarter {
	               .mixin-span(3, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-4,
	           .one-third {
	               .mixin-span(4, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-5 {
	               .mixin-span(5, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-6,
	           .one-half {
	               .mixin-span(6, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-7 {
	               .mixin-span(7, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-8,
	           .two-thirds {
	               .mixin-span(8, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-9,
	           .three-quarters {
	               .mixin-span(9, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-10 {
	               .mixin-span(10, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-11 {
	               .mixin-span(11, @gutter-pc, @gutter_px, @padding, @max_columns);
	           }
	           .zg-col-12,
	           .one-whole {
	               .stacked()
	           }
	           
	           
	           
	           .zen-push,
	           .zen-pull {
	           		position:relative;
	           		z-index:1;
	           }
	           
	           
	           .zen-push-11 {.pushcol(11, @gutter-pc)}	
	           .zen-push-10 {.pushcol(10, @gutter-pc)}	
	           .zen-push-9 {.pushcol(9, @gutter-pc)}	
	           .zen-push-8 {.pushcol(8, @gutter-pc)}	
	           .zen-push-7 {.pushcol(7, @gutter-pc)}	
	           .zen-push-6 {.pushcol(6, @gutter-pc)}	
	           .zen-push-5 {.pushcol(5, @gutter-pc)}	
	           .zen-push-4 {.pushcol(4, @gutter-pc)}	
	           .zen-push-3 {.pushcol(3, @gutter-pc)}	
	           .zen-push-2 {.pushcol(2, @gutter-pc)}	
	           .zen-push-1 {.pushcol(1, @gutter-pc)}	
	           
	           .zen-pull-11 {.pullcol(11, @gutter-pc)}
	           .zen-pull-10 {.pullcol(10, @gutter-pc)}
	           .zen-pull-9 {.pullcol(9, @gutter-pc)}
	           .zen-pull-8 {.pullcol(8, @gutter-pc)}
	           .zen-pull-7 {.pullcol(7, @gutter-pc)}
	           .zen-pull-6 {.pullcol(6, @gutter-pc)}
	           .zen-pull-5 {.pullcol(5, @gutter-pc)}
	           .zen-pull-4 {.pullcol(4, @gutter-pc)}
	           .zen-pull-3 {.pullcol(3, @gutter-pc)}
	           .zen-pull-2 {.pullcol(2, @gutter-pc)}
	           .zen-pull-1 {.pullcol(1, @gutter-pc)}
     }      
          	
