/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .textshadow() {
 	  text-shadow:1px 1px 0 rgba(255,255,255, 0.3);
 }
 
// Drop shadows
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}
 
 
 .noshadow () {
 	text-shadow: none
 }


 .myshadow() {
 	.box-shadow(inset 0px 0px 4px rgba(0,0,0,.06));
 }
 
 .myshadow() {
	.box-shadow(inset 0px 0px 4px rgba(0,0,0,.06));
}	