/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-menu-toggle {

	@media (min-width:@nav-collapse) {
		display:none;
		
		li {
			display:block;
			float:none;
			
			ul {
				.make_visible(); 
				margin-left:0;
				float:left;
				width:100%
			}
			
			&:hover > ul{
				margin-left:0px;
				
				ul {
					margin-left:20px;
				}
			}
		}
		
		.zen-group {
		
			ul {
				margin-left:0px !important;
			}
				
			&:hover > ul{
				margin-left:0px;
				
				ul {
					margin-left:0;
				}
				
			}
		}
	}
	
	@media (min-width:787px) {
		//display:block !important;
	}

}


@media (min-width:@nav-collapse) {
	.zen-menu {
		clear:both
	}
}



@media (max-width:@nav-collapse) {
	.zen-menu-btn {
		display:block
	}
}

@media (min-width:@nav-collapse) {
	.zen-menu-btn {
		display:none
	}
}