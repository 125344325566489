/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */
 
 /* Panel
-----------------------------------------------------------------------------*/
.block {

	//padding:@gutter;
	background:#fafafa;
	border:1px solid #eee;
	padding:@gutter;
	
	// Block colours
	
	&.primary1 {
		background:@primary;
		border:1px solid darken(@primary, 4%);
		
		p,h1,h2,h3,h4,h5,h6 {
			color:#fff
		}
	}
	
	&.primary2 {
		background:@primary2;
		border:1px solid darken(@primary2, 4%);
		
		p,h1,h2,h3,h4,h5,h6 {
			color:#fff
		}
	}
	
	&.primary3 {
		background:@primary3;
		border:1px solid darken(@primary3, 4%);
		
		p,h1,h2,h3,h4,h5,h6 {
			color:#fff
		}
	}
	
	&.secondary1 {
		background:@secondary;
		border:1px solid darken(@secondary, 4%);
		
		p,h1,h2,h3,h4,h5,h6 {
			color:#fff
		}
	}
	
	&.secondary2 {
		background:@secondary2;
		border:1px solid darken(@secondary2, 4%);
		
		p,h1,h2,h3,h4,h5,h6 {
			color:#fff
		}
	}
	
	&.secondary3 {
		background:@secondary3;
		border:1px solid darken(@secondary3, 4%);
		
		p,h1,h2,h3,h4,h5,h6 {
			color:#fff
		}
	}
	
	
	// Borders
	
	&.border {
		
		background:none;
		
		p{
			color:@text-color
		}
		
		p,h1,h2,h3,h4,h5,h6 {
			color:@heading-color
		}
		
	}
	
	&.rounded {
		border-radius: 2px
	}

}