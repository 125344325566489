/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-menu-pills {
	
	li {
		
		> a,
		> .zen-menu-heading {
		
			padding:10px 20px 0;
			border-radius:4px;
			
			&:hover {
				color:#fff;
				background:#999;
			}
		}
		
		&.active {
			
			> a,
			> .zen-menu-heading {
				background:#999;
				color:#fff;
				padding:10px 20px 0;
				border-radius:4px
			}
			
			ul {
				li > a,
				li > .zen-menu-heading {
					.reset();
				}
			}
			
		}
		
		ul {
			 a,
			 .zen-menu-heading {
				.reset();
			}
			
			li {
				
				a,
				.zen-menu-heading {
					&:hover {
						color:#999
					}
				}
			}
		}
	}
}