/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */


/* Tools
-----------------------------------------------------------------------------*/
.floatleft {float: left;margin: 0 3% 30px 0}
.floatright {float: right;margin: 0 0 30px 3%}
.divider {.clearfix}


/* A legacy rule because we used clear so much before */
.clear {.clearfix;display:block}

.clearfix {
	clear:both;
		&:before,
		&:after {
		  content: " "; // 1
		  display: table; // 2
		}
		&:after {
		  clear: both;
		}
	
	}

/* Position on nav and logo etc */
.zenleft{float: left;}
.zencenter {float: none;text-align: center}
.zenright {float: right}



.hide {
	display:none !important
}
.zen-reset-list {

	margin:0;
	padding:0;
	
	li,
	span {
		display:inline-block;
		list-style-type:none;
		padding:0 4px;
	}
}	

.padding{
	.padding()
}