/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */

// Some basic adjustments on things that get adjusted across all of our themes



 //
 // Make menus in the top row go horizontal
 // Make top4 menus float right
 //
 
 #topwrap {
 	
 	padding-top:2px;
 	
 	ul {
 		.flatlist()
 	}
 	
 	#top4 {
 		ul {
 			
 			
 			@media screen and (min-width: @gridcollapse) {
 				float:right;
 			}
 		}
 	}
 }
 
 
 
 
 // Below Content
 #belowcontent {
 	margin-top:@gutter;
 }
 
 
 // Above Content
 #abovecontent {
 	margin-bottom:@gutter;
 }


//
// Footer Adjustments
// Adds some space below the footer

 #footerwrap {
 	margin-bottom:@gutter * 1.5;
 	padding-top:@gutter;
 	font-size:90%;
 	
 	.moduletable {
 		clear:both;
 		margin-bottom:30px;
 		float:left;
 		width:100%;
 		
 	}
 	
 	ul {
 		clear:both;
 		.flatlist
 	}
 	
 	@media screen and (max-width:@gridcollapse) {
 		#zen-copyright {
 			text-align:left;
 			margin:@gutter /2 0
 		}
 	}
 	
 	.zen-spotlight {
 		padding-top:0;
 		margin-top:0;
 	}
} 


// Tabbed Content
#tabwrap {
	
	margin-top:@gutter;
	float:left;
	width:100%;
}

.responsive-enabled {	
	@media screen and (max-width:@gridcollapse) {
		.nav-tabs {
			li {
				margin-bottom:4px;
				display:block;
				float:left;
				width:100%;
				
				a {
					float:left;
					width:100%
				}
			}
		}
	}
}