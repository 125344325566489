.btn() {

	color:@btn-primary-color;
	background: @btn-primary-bg;
	border: 1px solid @btn-primary-border;
	padding:10px;
	border-radius:2px;
	box-shadow:none;
	text-shadow:none;
	
	.transition();
	
		&:hover {
			color:@btn-primary-color-hover;
			background: @btn-primary-bg-hover;
			border: 1px solid @btn-primary-border-hover;
			text-decoration:none
		}	
}

.btn-active() {
	
			color:@btn-primary-color-hover;
			background: @btn-primary-bg-hover;
			border: 1px solid @btn-primary-border-hover;
			border-radius:0px !important;
	
}

.btn-empty() {
	.btn();
	background:none;
	color:@btn-primary-border
}

.row-color(@bg, @textcolor, @headingcolor, @linkcolor) {

		background:@bg;
		color:@textcolor;

		
		h1,h2,h3,h4,h5,h6,p,li,span {
			color:@headingcolor
		}
		
		a {
			color:@linkcolor
		}
		
		li {
			border-bottom:1px solid lighten(@bg,10%);
			
			a {
				color:@linkcolor
			}
		}
		
		.zen-spotlight {
			border:0
		}

}