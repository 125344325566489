/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
#off-canvas-menu {

	margin-top:40px;
	
	ul {
		
		float:left;
		width:100% !important;
		margin-bottom:@gutter * 2;
		
		ul {
			
			position:relative;
			opacity:1;
			visibility:visible;
			overflow:auto;
			height:auto;
			background:none;
			padding:0;
			min-width:none;
		}
	}
	
	
}