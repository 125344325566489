/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework Responsive2 - 1.3.0 | ZGF v4 1.3
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 31 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 31 2015
 *------------------------------------------------------------------------------
 */
 
 
 .zen-menu {
	
	// Module
	.zen-menu-module {
		
		padding:10px 0 20px;
		
		h3 {
			margin:0;
		}
		
		#modlgn-remember{
			margin-left:0 !important
		}
	}
	
}