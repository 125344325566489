/** 
 *------------------------------------------------------------------------------
 * @package        Xero 1.3.0
 *------------------------------------------------------------------------------
 * @copyright   Copyright (C) Joomlabamboo, March 10 2015
 * @license     http://www.gnu.org/licenses/gpl.html GNU General Public License version 2 or later; 
 * @Link:       http://www.joomlabamboo.com
 * @packaged: 	March 10 2015
 *------------------------------------------------------------------------------
 */
 

// Import Template Variables
@import "variables.less";

// Import template and framework mixins
@import "../zengrid/libs/zengrid/less/_mixins.less";


// Import Base Framework styling
// Includes grids, reset, tools and other base style
@import "../zengrid/libs/zengrid/less/zen-base.less";
@import "mixins.less";
@import "../zengrid/libs/zengrid/less/fontawesome/font-awesome-all.less";
@import "../zengrid/libs/zengrid/less/animate/animate-library.less";

// Joomla
@import "joomla.less";
@import "frontend-edit.less";


// Menu
@import "menu.less";
@import "off-canvas.less";
@import "buttons.less";
@import "logo.less";

// Display
@import "typography.less";
@import "blocks.less";
@import "socialicons.less";
@import "modules.less";


// Layout and Style
@import "layout.less";
@import "style.less";

// Effects
@import "totop.less";

// Zen Stuff
@import "zentools.less";
@import "zenkit.less";

// Media Queries
@import "toggle.less";
@import "mediaqueries.less";


.zen-container {
	max-width:80%;
	width:600px
}

.error-page {
	padding-top:@gutter * 3
}


#errorwrap,#logowrap,#errorboxheader {
	
	text-align:center;
	
	h1,h2,p,h3 {
		text-align:center
	}
}	



section {
	max-width:90%;
	margin:0 auto
}

#searchbox {
	float:left;
	width:100%;
	margin:@gutter * 2 auto;
	
	input {
		width:75%;
		margin-bottom:@gutter;
		float:left;
		
		@media screen and (max-width:787px) {
			width:100%
		}
	}
	
	button {
		padding:10px;
		width:20%;
		margin:0;
		float:right;
		
		@media screen and (max-width:787px) {
			width:100%
		}
	}
}

#errorwrap {

	margin:0 auto;
	
	
}

ul {

	text-align:left
}


.padding-style {
	float:left;
	width:100%;
	background:@banner-bg;
	border:1px solid @banner-border-color;
	padding:@banner-padding;
	margin-bottom:@gutter;
}
