/** 
 *------------------------------------------------------------------------------
 * @package       Zen Grid Framework 
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2006-2014 Joomlabamboo.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; 
 * @Link:         http://www.joomlabamboo.com
 *------------------------------------------------------------------------------
 */


// -------------------------------------------------------
// Zentools
// -------------------------------------------------------

.zentools {

	&.grid {	
		[class^="grid_"] {
			margin-left:0
		}
		
		h3 {
			margin-top:0
		}
	}
	
	&.list {
		h3 {
			margin-top:0
		}
		
	}
	
	.zentitle {
		
		padding:0;
		
		h2 {
			margin-top:0
		}
	}
	
	.zen-boxed {
		
		.zentitle {
			padding:0 10px
		}

	}

	
	&.slideshow {
	
		.zentitle {
		
			a {
				font-size:60%;
				padding:0px;
			}
				
			h2 {
				margin:0 !important;
				line-height:0.8em;
				padding-bottom:0
			}
		}
		
		.zenmore a{
			display:inline
		}
	}
	
	&.accordion {
	
		.allitems.container {
			padding:@gutter 
		}
	
		&.zen-boxed {
		
			.zen-title{
					border:0 !important
				}
				
				.zeninner {
					padding-bottom:0;
					border-bottom:1px solid @border-color;
					margin-bottom:@gutter;
					box-shadow:0px 1px 1px 0px rgba(180, 180, 180, 0.1)
				}
			}
		}
		
		&.zen-flat {
			.zentitle{
					border-bottom:1px solid @border-color !important
			}
		}
	}

.accordion.zen-boxed .zentitle {
	border:0  !important
}

#bannerwrap {
	
	.slideshowstandard {
		ul.flex-direction-nav {
			li {
			
				margin-top:-20px;
				
				a {
					padding:20px !important;
					border-radius:0;
					
					&:before {
						margin:-8px 0 0 -8px !important;
						float:left
					}
				}
			}
		}
	}	
}


ul#filters li {
	margin:0 10px 20px 0 !important
}



