
// MeanMenu 2.0.7
// To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)
// 

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
}

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: @navbar-default-bg;
	padding: 4px 0 0;
	min-height: 42px;
	margin-bottom:10px;
	z-index: 99;
}

.mean-container li.parent .zen-icon-plus,
.mean-container li.parent .zen-icon-minus {
	float:right;
	color:@text-color
}

.mean-container a.meanmenu-reveal {
	width: 22px;
	height: 22px;
	padding: 13px 13px 11px 13px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: @navbar-default-link-color;
	text-decoration: none;
	font-size: 16px;
	text-indent: -9999em;
	line-height: 22px;
	font-size: 1px;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
	
	&:hover {
		color:@navbar-default-link-hover-color
	}
}

.mean-container a.meanmenu-reveal span {
	display: block;
	background: @navbar-default-link-color;
	height: 3px;
	margin-top: 3px;
	
	&:hover {
		color:@navbar-default-link-hover-color
	}
}

.mean-container .mean-nav {
	float: left;
	width: 100%;
	margin-top: 44px;
}

.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}

.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
	padding:0
}

.mean-container .mean-nav ul li a,
.mean-container .mean-nav ul li span.zen-menu-heading{
	display: block;
	float: left;
	width: 90%;
	padding: 1em 5%;
	margin: 0;
	text-align: left;
	cursor:pointer;
	color: @text-color;
	border-top: 1px solid #383838;
	border-top: 1px solid rgba(255,255,255,0.05);
	text-decoration: none;
	text-transform: uppercase;
	
	&:hover {
		color:@link-hover-color
	}
}

.mean-container .mean-nav ul li li a,
.mean-container .mean-nav ul li li span.zen-menu-heading {
	width: 80%;
	padding: 0.9em 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(255,255,255,0.05);
	opacity: 0.75;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a,
.mean-container .mean-nav ul li.mean-last span.zen-menu-heading {
	border-bottom: none;
	margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a,
.mean-container .mean-nav ul li li li span.zen-menu-heading {
	width: 70%;
	padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a,
.mean-container .mean-nav ul li li li li span.zen-menu-heading {
	width: 60%;
	padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a,
.mean-container .mean-nav ul li li li li li span.zen-menu-heading {
	width: 50%;
	padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.05);
}

.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	width:20px;
	height:20px;
	padding: 12px !important;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	font-size:1.4em !important;
	border: none !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
	color:@link-hover-color
}

.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}


.mean-remove {
	display: none !important;
}

.mean-container .zen-menu-caption {
	font-size:11px;
	font-style:italic;
	color:#999;
	margin-left:10px;
	
}

.mean-nav
	.moduletable {
		box-sizing:border-box;
		padding:20px;
		
		#login-form ul {
			margin-bottom:@gutter * 2
		}
		
		.form-group {
			margin-bottom:0;
		}
		
		input {
			width:100%;
			box-sizing:border-box;
			
			&[type="checkbox"],
			&[type="radio"] {
				width:auto
			}
		}
	}
	#off-canvas-menu {
		margin:0 !important;
		
		ul {
			margin:0 !important;
		}
	
}